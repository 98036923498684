import PropTypes from "prop-types";

export default {
  title: PropTypes.string,
  googleKey: PropTypes.string,
  categories: PropTypes.array,
  attributes: PropTypes.array,
  logo: PropTypes.shape({
    url: PropTypes.string,
    type: PropTypes.string,
  }),
  themeColor: PropTypes.string,
};
