import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "../../ButtonOrLink";
import { IconArrow } from "../../Icons";
import T from "../../Translate";

import styles from "./DetailNav.module.scss";

const DetailNav = () => {
  const focusEl = useRef(null);
  // Focus on results button when details are opened
  useEffect(() => {
    focusEl.current.focus();
  }, []);

  return (
    <nav className={classNames("is-hidden-print", styles.detailnav)}>
      <Link
        to={{ pathname: "/", search: window.location.search }}
        innerRef={focusEl}
        iconLeft={<IconArrow small rotation={90} />}
      >
        <T translate="detail.back_to_results_button" />
      </Link>
    </nav>
  );
};

DetailNav.propTypes = {};

export default DetailNav;
