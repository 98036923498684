import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "focus-visible";
import "ie11-custom-properties";
import { checkFlexGap } from "frontend/helpers";

import i18n from "./i18n";
import "frontend/index.scss";

import App from "frontend/App";

const element = <App />;

function addi18nResources(localeObj, ns, keyName) {
  if (!localeObj || !ns || !keyName) return;

  Object.entries(localeObj).forEach(([key, value]) => {
    i18n.addResources(key, ns, {
      [keyName]: value,
    });
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const mount = document.getElementById("frontend-mount");
  const languages = [];

  // Add translations i18n namespace
  const elTranslations = document.getElementById("translations");
  if (elTranslations && elTranslations.content) {
    const translations = JSON.parse(elTranslations.content);
    // Setting deep = true and overwrite = true overwrites default translations
    Object.entries(translations).forEach(([key, value]) => {
      languages.push({ label: value.label, value: key });
      i18n.addResourceBundle(key, "translation", value.translation, true, true);
    });

    languages.sort((a, b) => {
      if (a.value === "en" || a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
  }

  // Add announcements to i18n namespace
  const el = document.getElementById("announcement");
  if (el && el.dataset.id) {
    const { title, body } = el.dataset;
    addi18nResources(JSON.parse(title), "announcement", "title");
    addi18nResources(JSON.parse(body), "announcement", "body");
  }

  // Get global data props
  const globalProps = JSON.parse(mount.dataset.props);

  // Add categories to i18n instance
  const { categories, attributes } = globalProps;
  if (categories) {
    JSON.parse(categories).forEach(({ id, label }) => {
      addi18nResources(label, "categories", `category_${id}`);
    });
  }

  // Add attributes to i18n instance
  if (attributes) {
    JSON.parse(attributes).forEach(({ id, label }) => {
      addi18nResources(label, "attributes", `attribute_${id}`);
    });
  }

  checkFlexGap();

  ReactDOM.render(
    <App
      globalData={{
        ...globalProps,
        categories: JSON.parse(categories),
        attributes: JSON.parse(attributes),
        languages,
      }}
    />,
    mount
  );
});
