import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { usePopoverState, Popover, PopoverDisclosure } from "reakit/Popover";
import { useCheckboxState } from "reakit/Checkbox";
import { useTranslation } from "react-i18next";
import { useFilters } from "frontend/hooks";
import { IconArrow, IconPin } from "../Icons";
import Checkbox from "../Checkbox";

import styles from "./Dropdown.module.scss";

const MultiSelect = ({
  id,
  ariaLabel,
  items,
  onChange,
  placeholder: initPlaceholder,
}) => {
  const popover = usePopoverState({ gutter: 0, placement: "bottom-start" });
  const checkbox = useCheckboxState({ state: [] });
  const { t } = useTranslation();
  const {
    params: { reset },
  } = useFilters();

  const placeholder = useMemo(() => {
    const values = checkbox.state;

    // Get the current values and return placeholder text
    if (values.length > 0) {
      return items
        .filter((item) => values.includes(item.value))
        .map((item) => t(item.labelKey, { ns: "categories" }))
        .join(", ");
    }

    return initPlaceholder;
  }, [checkbox.state, items, initPlaceholder, t]);

  useEffect(() => {
    if (!onChange) return;

    onChange(checkbox.state);
  }, [checkbox.state, onChange]);

  useEffect(() => {
    if (reset) {
      checkbox.setState([]);
    }
  }, [reset, checkbox]);

  return (
    <div className={styles.dropdown}>
      <PopoverDisclosure {...popover} className={styles.dropdown__select}>
        <span
          className={classNames(
            "t-text-truncate",
            styles.dropdown__placeholder
          )}
        >
          {placeholder}
        </span>
        <IconArrow className={styles.dropdown__arrow} />
      </PopoverDisclosure>
      <Popover
        {...popover}
        aria-label={ariaLabel}
        className={styles.dropdown__menu}
      >
        <ul>
          {items.map(({ labelKey, value, pinColor, icon }, i) => (
            <li className={styles.dropdown__menuItem} key={i}>
              <Checkbox checked={false} value={value} checkboxProps={checkbox}>
                <span className={styles.dropdown__checkboxLabel}>
                  <span className={styles.dropdown__checkboxText}>
                    {t(labelKey, { ns: "categories" })}
                  </span>
                  {pinColor && (
                    <IconPin
                      className={styles.dropdown__checkboxIcon}
                      color={pinColor}
                      icon={icon}
                      height={24}
                      width={24}
                    />
                  )}
                </span>
              </Checkbox>
            </li>
          ))}
        </ul>
      </Popover>
    </div>
  );
};

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default MultiSelect;
