import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "reakit/Button";
import { IconArrow } from "../Icons";

import styles from "./Dropdown.module.scss";

const Select = ({ id, ariaLabel, items, onChange, defaultValue }) => {
  const { t } = useTranslation();

  const handleOnChange = (event) => {
    if (!onChange) return;
    const option = items.find(
      (item) => String(item.value) === event.target.value
    );
    onChange(option);
  };

  if (!items || items.length === 0) return;

  return (
    <div className={styles.dropdown}>
      <select
        id={id}
        className={styles.dropdown__select}
        onChange={handleOnChange}
        aria-label={ariaLabel}
        defaultValue={defaultValue}
      >
        {items.map((item, i) => (
          <option key={i} value={item.value}>
            {t(item.label, {
              ...(item.count ? { count: item.count } : {}),
            })}
          </option>
        ))}
      </select>
      <IconArrow className={styles.dropdown__arrow} />
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Select;
