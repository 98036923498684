import React, { useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useCheckboxState } from "reakit/Checkbox";
import { helpLink } from "frontend/shared/menuLinks";
import { Button, Link } from "../ButtonOrLink";
import MobileToggle from "../MobileToggle";
import T from "../Translate";
import FiltersSearch from "./FiltersSearch";
import FiltersCategory from "./FiltersCategory";
import FiltersDistance from "./FiltersDistance";
import FiltersOpen from "./FiltersOpen";
import FiltersAttributes from "./FiltersAttributes";
import { useTranslation } from "react-i18next";
import {
  useFilters,
  useMobile,
  useGlobal,
  useQueryFilters,
} from "frontend/hooks";
import { isEmpty } from "frontend/helpers";
import { filterShape } from "frontend/shapes";
import FiltersViewResults from "./FiltersViewResults";

import styles from "./Filters.module.scss";

const ZIPCODE_REGEX = /^\d{5}$|^\d{5}-\d{4}$/;

const Filters = () => {
  const { categories, attributes } = useGlobal();
  const { t } = useTranslation();
  const { params, setFilters, resetFilters } = useFilters();
  const { filtersOpen } = useMobile();
  const { query, updateQuery } = useQueryFilters();
  // States
  const openDates = useCheckboxState({ state: [] });
  const openTimes = useCheckboxState({ state: [] });
  const attributesState = useCheckboxState({ state: [] });
  const formEl = useRef();

  useEffect(() => {
    handleDateChange(openDates.state);
  }, [openDates.state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleTimeChange(openTimes.state);
  }, [openTimes.state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleFilterChange(attributesState.state);
  }, [attributesState.state]); // eslint-disable-line react-hooks/exhaustive-deps

  // Clear checkbox states if filters are reset
  useEffect(() => {
    if (params.reset) {
      openDates.setState([]);
      openTimes.setState([]);
      attributesState.setState([]);
      formEl.current.reset();
    }
  }, [params.reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDistanceChange = useCallback(
    (item) => {
      setFilters({
        distance: item.value,
      });
    },
    [setFilters]
  );

  const handleOnSearch = useCallback(
    (value) => {
      updateQuery({ q: value });
    },
    [updateQuery]
  );

  const handleDateChange = useCallback(
    (value) => {
      if (value.length > 0) {
        setFilters({ open_days: value });
      } else if (params.open_days && params.open_days.length !== 0) {
        setFilters({ open_days: null });
      }
    },
    [setFilters, params.open_days]
  );

  const handleTimeChange = useCallback(
    (value) => {
      if (value.length > 0) {
        const time = `${value[0]}:01`;
        setFilters({ open_at_time: time });
      } else if (params.open_at_time && params.open_at_time.length !== 0) {
        setFilters({ open_at_time: null });
      }
    },
    [setFilters, params.open_at_time]
  );

  const handleFilterChange = useCallback(
    (value) => {
      if (value.length > 0) {
        setFilters({ location_attributes: value });
      } else if (
        params.location_attributes &&
        params.location_attributes.length !== 0
      ) {
        setFilters({ location_attributes: null });
      }
    },
    [setFilters, params.location_attributes]
  );

  const handleReset = useCallback(() => {
    formEl.current.reset();
    resetFilters();
  }, [resetFilters]);

  const handleSubmit = () => {};

  return (
    <form
      aria-label={t("filters.aria_header")}
      className={classNames(styles.filters, "is-hidden-print")}
      id="filters"
      ref={formEl}
    >
      <FiltersSearch onSearch={handleOnSearch} defaultValue={query.q} />
      <MobileToggle containerClass={styles.filters__toggle} />
      <div
        className={classNames(
          {
            "is-hidden-mobile": !filtersOpen,
          },
          styles.filters__wrapper
        )}
      >
        <h3 className={styles.filters__header}>{t("filters.header")}</h3>
        <div
          className={classNames(
            styles.filters__dropdowns,
            styles.filters__group
          )}
        >
          {categories && categories.length > 0 && (
            <FiltersCategory categories={categories} />
          )}
          <FiltersDistance onDistanceChange={handleDistanceChange} />
        </div>
        <div className={styles.filters__group}>
          <FiltersOpen
            dateCheckboxState={openDates}
            timeCheckboxState={openTimes}
          />
        </div>
        {attributes && attributes.length > 0 && (
          <div className={styles.filters__group}>
            <FiltersAttributes
              checkboxState={attributesState}
              attributes={attributes}
            />
          </div>
        )}
        <div className={styles.filters__reset}>
          <Button onClick={handleReset} type="reset" secondary>
            <T translate="filters.reset_filters_button" />
          </Button>
          <FiltersViewResults />
        </div>
        <p className={`is-hidden-print ${styles.filters__group}`}>
          <Link href={helpLink} target="_blank">
            <T translate="header.click_here_for_assistance" isHTML />
          </Link>
        </p>
      </div>
    </form>
  );
};

Filters.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.shape(filterShape)),
  categories: PropTypes.arrayOf(PropTypes.shape(filterShape)),
};

export default Filters;
