import React from "react";
import PropTypes from "prop-types";
import { getCategoriesList } from "frontend/helpers";
import { useTranslation } from "react-i18next";

const CategoriesList = ({ categories, tag, className }) => {
  const { t } = useTranslation();
  const Tag = tag ? tag : "span";

  if (!categories || categories.length === 0) return null;

  const categoriesList = categories
    .map(({ id }) => t(`category_${id}`, { ns: "categories" }))
    .join(", ");

  return <Tag className={className}>{categoriesList}</Tag>;
};

CategoriesList.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array,
  tag: PropTypes.string,
};

export default CategoriesList;
