import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useGlobal } from "frontend/hooks";

const SEO = () => {
  const { t, i18n } = useTranslation();
  const {
    metaData: { title, description },
  } = useGlobal();
  const defaultTitle = t(title);
  const defaultDescription = t(description);

  return (
    <Helmet
      htmlAttributes={{
        dir: i18n.dir(),
        lang: i18n.language,
      }}
      defaultTitle={defaultTitle}
    >
      <meta name="description" content={defaultDescription} />
      <meta property="og:locale" content={i18n.language} />
      <meta property="og:title" content={defaultTitle} />
      <meta property="og:description" content={defaultDescription} />
      <meta
        property="og:url"
        content={window.location ? window.location.href : ""}
      />
    </Helmet>
  );
};

SEO.propTypes = {};

export default SEO;
