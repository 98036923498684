import React from "react";
import PropTypes from "prop-types";

const BAG_ICON = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 31.3 40"
    width="31.3"
    height="40"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="currentColor"
      d="M27.2,1H4.1C2.2,1,0.6,2.6,0.6,4.5v22.6c0,1.9,1.6,3.4,3.4,3.4h6.4l5.3,8.6l5.3-8.6h6.4c1.9,0,3.4-1.6,3.4-3.4V4.5
C30.7,2.6,29.1,1,27.2,1z"
    />
    <g id="bag">
      <rect x="12.7" y="4.2" fill="#FFFFFF" width="6" height="2.3" />
      <polygon fill="#FFFFFF" points="12.2,4.2 9.9,5.2 9.9,9.8 12.2,9.8 	" />
      <polygon fill="#FFFFFF" points="21.6,5.2 19.3,4.2 19.3,9.8 21.6,9.8 	" />
      <path
        fill="#FFFFFF"
        d="M7.4,10.4v9.3c0,3.6,2.9,6.5,6.5,6.5h3.6c3.6,0,6.5-2.9,6.5-6.5v-9.3H7.4z M14.6,25L14.6,25
   c-0.3,0-0.7-0.1-0.9-0.1c-2.4-0.4-4.3-2-5.1-4.2l0,0l0.8-0.3l0,0c0.8,2.3,2.9,3.8,5.3,3.9l0,0V25z"
      />
    </g>
  </svg>
);

const DEFAULT_ICON = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 36.3 45"
    width="31.3"
    height="40"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="currentColor"
      d="M31.6,0.5H4.7c-2.2,0-4,1.8-4,4v26.2c0,2.2,1.8,4,4,4h7.4l6.1,10l6.1-10h7.4c2.2,0,4-1.8,4-4V4.5 C35.6,2.3,33.8,0.5,31.6,0.5z"
    />
    <path
      id="carrot"
      fill="#FFFFFF"
      d="M26.4,12.1c0.9-0.3,2.4-1.7,3.5-1.9c-1.2-0.4-2.5-0.5-3.7-0.4l0,0c0.7-1.1,1.1-2.1,1.3-3.3
	c-0.7,0.9-2.7,1.5-3.2,2.1l-0.1,0.1c-0.3-1.5-0.9-2.8-1.7-3.9c0.1,1.1-0.8,2.9-0.8,3.9c0,0.8,0.3,1.5,0.5,2.1
	c-1.6-0.8-3.1-0.5-4.3,0.1c0.4,0.3,0.8,0.4,1.1,0.7c0.1,0,0.1,0.1,0,0.3l0,0c-0.1,0.1-0.3,0.1-0.3,0c-0.3-0.3-0.8-0.5-1.2-0.7
	c-0.9,0.8-1.6,1.5-2.1,2.5c-0.4,0.9-2.7,5.3-3.2,6.5c-0.7,1.1-1.5,2.8-1.5,2.8c0.3,0.1,0.5,0.3,0.8,0.5c0.1,0,0.1,0.1,0,0.3l0,0
	c-0.1,0.1-0.3,0.1-0.3,0c-0.3-0.3-0.4-0.3-0.7-0.4c-1.3,2.1-2.7,4.4-2.3,4.7c0.3,0.3,1.3-0.3,2.5-1.1l5.2-3.5l0.8-0.5l1.7-1.2
	c-0.1-0.8-0.5-1.3-1.1-1.7c-0.1,0-0.1-0.1-0.1-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0c0.7,0.4,1.1,1.1,1.2,1.9l0.4-0.3l2.3-1.3
	c0.3-0.3,0.7-0.5,0.9-0.9c-0.1-1.1-0.5-2.1-1.3-2.9c-0.5-0.7-1.1-1.2-1.7-1.7c-0.1,0-0.1-0.1-0.1-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0
	c0.7,0.5,1.3,1.1,1.9,1.7c0.7,0.8,1.1,1.7,1.3,2.8c0.9-0.9,1.7-2.1,2.3-3.2c-0.3-0.8-0.9-1.5-1.5-2.1c-0.1,0-0.1-0.1-0.1-0.3l0,0
	c0.1-0.1,0.3-0.1,0.3,0c0.5,0.4,1.1,0.9,1.5,1.6c0.1-0.9-0.3-1.6-0.9-2.5C25.1,12.3,25.7,12.3,26.4,12.1z"
    />
  </svg>
);

const CARTON_ICON = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 31.3 40"
    width="31.3"
    height="40"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="currentColor"
      d="M27.2,1H4.1C2.2,1,0.6,2.6,0.6,4.5v22.6c0,1.9,1.6,3.4,3.4,3.4h6.4l5.3,8.6l5.3-8.6h6.4c1.9,0,3.4-1.6,3.4-3.4V4.5 C30.7,2.6,29.1,1,27.2,1z"
    />
    <g id="carton">
      <path
        fill="#FFFFFF"
        d="M18.7,7.6c-1.7,0-3.5,0-5.3,0V7.1h7.2V5.5c0-0.4-0.3-0.8-0.8-0.8h-7.9c-0.4,0-0.8,0.3-0.8,0.8v1.7 l-0.4,0.7l0,0l-0.1,0.1c-0.4,0.6-0.7,1.2-1.1,1.8l-0.3,0.5l-0.1,0.1l0,0.1l-0.4,0.8v13.9c0,0.8,0.7,1.5,1.5,1.5h7.4V11.4 c0-0.1-0.1-0.2-0.2-0.2h-6v-0.6H14c1.2,0,2.3,0,3.5,0c0.1,0,0.3-0.1,0.4-0.2c0.4-0.7,1.9-2.8,1.9-2.8H18.7z"
      />
      <path
        fill="#FFFFFF"
        d="M22.6,10.6C22.6,10.5,22.6,10.5,22.6,10.6l-1.9-3c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0l-1.8,2.8 c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1h1.7v0.6h-1.9c-0.1,0-0.2,0.1-0.2,0.2l0,15.2h2.7c0.8,0,1.5-0.7,1.5-1.5L22.6,10.6 C22.6,10.6,22.6,10.6,22.6,10.6z"
      />
    </g>
  </svg>
);

const BOWL_ICON = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 31.3 40"
    width="31.3"
    height="40"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="currentColor"
      d="M27.2,1H4.1C2.2,1,0.6,2.6,0.6,4.5v22.6c0,1.9,1.6,3.4,3.4,3.4h6.4l5.3,8.6l5.3-8.6h6.4c1.9,0,3.4-1.6,3.4-3.4V4.5 C30.7,2.6,29.1,1,27.2,1z"
    />
    <g id="bowl">
      <path
        fill="#FFFFFF"
        d="M24.3,15.6c-2.2-2.5-6-2.8-8.5-0.6c0,0-0.1,0.1-0.1,0.1c-2.5-2.3-6.3-2.1-8.6,0.3 c0,0-0.1,0.1-0.1,0.1H3.4v0.7c0,6.3,5.1,11.4,11.4,11.4h1.7c6.3,0,11.4-5.1,11.4-11.4v-0.7L24.3,15.6z M8.5,15.6 c1.8-1.3,4.2-1.3,6,0H8.5z M19.7,14.6c1.1,0,2.1,0.4,3,1h-6C17.6,15,18.6,14.6,19.7,14.6z M18.7,25.6c-0.4,0.1-0.8,0.1-1.1,0.1 v-0.9c3,0,5.6-1.8,6.7-4.5l0.4,0.1l0.5,0.2C24.1,23.3,21.6,25.2,18.7,25.6L18.7,25.6z"
      />
      <path
        fill="#FFFFFF"
        d="M12.3,10.8l-0.8-0.5c0.7-0.9,0.7-2.1,0-3c-0.9-1.2-0.9-2.9,0.1-4.1l0.8,0.5c-0.7,0.9-0.7,2.1,0,3 C13.2,8,13.2,9.6,12.3,10.8z"
      />
      <path
        fill="#FFFFFF"
        d="M19.8,10.8l-0.8-0.5c0.7-0.9,0.7-2.1,0-3c-0.9-1.2-0.9-2.9,0.1-4.1l0.8,0.5c-0.7,0.9-0.7,2.1,0,3 C20.7,8,20.7,9.6,19.8,10.8z"
      />
      <path
        fill="#FFFFFF"
        d="M16.1,13.1l-0.8-0.5c0.7-0.9,0.7-2.1,0-3c-0.9-1.2-0.9-2.9,0.1-4.1l0.8,0.5c-0.7,0.9-0.7,2.1,0,3 C17,10.3,17,11.9,16.1,13.1z"
      />
    </g>
  </svg>
);

const IconPin = ({ color, width, height, icon, ...otherProps }) => {
  const iconStyle = {
    color: color || "#000000",
    width: width || 36.3,
    height: height || 35.3,
    ...otherProps,
  };

  let Icon = DEFAULT_ICON;

  switch (icon) {
    case "bag.svg":
      Icon = BAG_ICON;
      break;
    case "bowl.svg":
      Icon = BOWL_ICON;
      break;
    case "carton.svg":
      Icon = CARTON_ICON;
      break;
    default:
      break;
  }

  return <Icon style={iconStyle} />;
};

IconPin.displayName = "IconPin";

IconPin.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  icon: PropTypes.oneOf(["bag.svg", "bowl.svg", "carton.svg", "default.svg"]),
};

export default IconPin;
