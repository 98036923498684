import React from "react";
import classNames from "classnames";
import { useLocations, useMobile } from "frontend/hooks";
import ResultsHeader from "./ResultsHeader";
import ResultsList from "./ResultsList";

import styles from "./Results.module.scss";

const Results = () => {
  const { locations, isLoading, isError } = useLocations();
  const { view, filtersOpen } = useMobile();

  return (
    <section
      className={classNames(
        {
          [styles["results--filtersOpen"]]: filtersOpen,
          "is-hidden-mobile": filtersOpen,
        },
        styles.results
      )}
    >
      <ResultsHeader
        count={locations ? locations.length : 0}
        isHidden={view !== "map"}
        isLoading={isLoading}
      />
      <ResultsList
        isLoading={isLoading}
        isError={isError}
        locations={locations}
        isHidden={view !== "results" || filtersOpen}
      />
    </section>
  );
};

Results.propTypes = {};

export default Results;
