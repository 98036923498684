import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useReduceMotion } from "frontend/hooks";
import { filterShape } from "frontend/shapes";
import SiteHeader from "../SiteHeader";
import Footer from "../Footer";
import Detail from "../Detail";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Filters from "../Filters";
import Map from "../Map";
import Results from "../Results";

import styles from "./Container.module.scss";

function Container() {
  const reduceMotion = useReduceMotion();

  return (
    <div className={styles.layout}>
      <SiteHeader containerClass={styles.layout__header} />
      <Sidebar containerClass={styles.layout__sidebar}>
        <Header />
        <Filters />
        <Results />
        <Footer />
      </Sidebar>
      <Route path="/locations/:location">
        {({ match }) => (
          <CSSTransition
            in={match !== null}
            timeout={reduceMotion ? 20 : 300}
            classNames="slide"
            unmountOnExit
          >
            <Detail containerClass={styles.layout__sidebar} />
          </CSSTransition>
        )}
      </Route>
      <Map containerClass={styles.layout__map} />
    </div>
  );
}

Container.propTypes = {};

export default Container;
