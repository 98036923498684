import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useGlobal } from "frontend/hooks";
import { Link } from "../ButtonOrLink";
import {
  menuLinks,
  missionLink,
  nondiscriminationLink,
  helpLink,
} from "../../shared";
import T from "../Translate";

import styles from "./Footer.module.scss";

const Footer = () => {
  const { email } = useGlobal();

  return (
    <footer className={classNames("is-hidden-print", styles.footer)}>
      <p className={styles.footer__lead}>
        <T translate="footer.having_trouble_text" />{" "}
        <T translate="footer.click_here_for_complete_list" isHTML />
      </p>
      <div>
        <h3 className={styles.footer__header}>
          <T translate="footer.our_mission_header" />
        </h3>
        <p>
          <T translate="footer.our_mission_text" />{" "}
          <T translate="footer.our_mission_read_more_text" isHTML />
        </p>
      </div>
      <div>
        <h3 className={styles.footer__header}>
          <T translate="footer.non_discrimination_header" />
        </h3>
        <p>
          <T translate="footer.non_discrimination_text" />{" "}
          <T translate="footer.non_discrimination_read_more_text" isHTML />
        </p>
      </div>
      {email && (
        <div>
          <Link href={`mailto:${email}`}>
            <T translate="footer.email_us_link" />
          </Link>
        </div>
      )}
      <div className={styles.footer__quicklinks}>
        <h3 className={styles.footer__header}>
          <T translate="footer.quicklinks_header" />
        </h3>
        <ul>
          {menuLinks
            .filter((item) => item.footerMenu)
            .map((item) => (
              <li key={item.id} className={styles["footer__nav-item"]}>
                <T translate={item.textKey} isHTML />
              </li>
            ))}
        </ul>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
