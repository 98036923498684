import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGlobal } from "frontend/hooks";
import { IconPin } from "frontend/components/Icons";
import { Button } from "frontend/components/ButtonOrLink";

import styles from "./MapLegend.module.scss";

const MapLegend = React.forwardRef((props, ref) => {
  const [hidden, setHidden] = useState(true);
  const { categories } = useGlobal();
  const { t } = useTranslation();

  const onClick = () => {
    setHidden(!hidden);
  };

  return categories && categories.length > 1 ? (
    <div ref={ref} className={styles.mapLegend} aria-live="polite">
      <ul
        id="legend"
        className={styles.mapLegend__list}
        aria-hidden={hidden}
        aria-labelledby="legendButton"
      >
        {categories.map(({ labelKey, pinColor, icon }, i) => (
          <li key={i} className={styles.mapLegend__item}>
            <IconPin color={pinColor} icon={icon} height={24} width={24} />
            <span className={styles.mapLegend__label}>
              {t(labelKey, { ns: "categories" })}
            </span>
          </li>
        ))}
      </ul>
      <div
        id="legendButton"
        className={styles.mapLegend__button}
        aria-controls="legend"
      >
        <Button onClick={onClick} type="button">
          {t(hidden ? "map.show_legend" : "map.hide_legend")}
        </Button>
      </div>
    </div>
  ) : null;
});

MapLegend.displayName = "MapLegend";

MapLegend.propTypes = {};

export default MapLegend;
