import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { openList, openTimeList } from "../../../shared/filters";
import Checkbox from "../../Checkbox";

import styles from "./FiltersOpen.module.scss";
import filterStyles from "../Filters.module.scss";

const FiltersOpen = ({ dateCheckboxState, timeCheckboxState }) => {
  const { t } = useTranslation();

  return (
    <div>
      <fieldset>
        <legend className={filterStyles.filters__label}>
          {t("filters.open_header")}
        </legend>
        <ul className={styles.filtersopen__list}>
          {openList.map((item) => (
            <li className={styles.filtersopen__item} key={item.id}>
              <Checkbox
                checked={false}
                value={item.value}
                checkboxProps={dateCheckboxState}
              >
                {t(item.label)}
              </Checkbox>
            </li>
          ))}
          {openTimeList.map((item) => (
            <li className={styles.filtersopen__item} key={item.id}>
              <Checkbox
                checked={false}
                value={item.value}
                checkboxProps={timeCheckboxState}
              >
                {t(item.label)}
              </Checkbox>
            </li>
          ))}
        </ul>
      </fieldset>
    </div>
  );
};

FiltersOpen.propTypes = {
  dateCheckboxState: PropTypes.object,
  timeCheckboxState: PropTypes.object,
};

export default FiltersOpen;
