import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { distanceList } from "frontend/shared/filters";
import Dropdown from "../Dropdown";

import styles from "./Filters.module.scss";

const FiltersDistance = ({ onDistanceChange }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.filters__distance}>
      <label htmlFor="distanceDropdown" className={styles.filters__label}>
        {t("filters.distance_label")}
      </label>
      <Dropdown
        large
        id="distanceDropdown"
        items={distanceList}
        ariaLabel={t("filters.distance_dropdown_label")}
        onChange={onDistanceChange}
        defaultValue={distanceList[3].value}
      />
    </div>
  );
};

FiltersDistance.propTypes = {
  onDistanceChange: PropTypes.func,
};

export default FiltersDistance;
