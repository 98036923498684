import React, { createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { transformFilterArray } from "frontend/helpers";
import { globalDataShape } from "frontend/shapes";
import { useUserLocation } from "frontend/hooks";

export const GlobalContext = createContext();

// Global data from meta tags and data-props
export const GlobalProvider = ({ value, children }) => {
  const { locationString, isLoading, isError } = useUserLocation();

  const { categories, attributes, title, themeColor = "#3E9F0A" } = value;

  // Transform categories and attributes
  const categoriesList = transformFilterArray(categories, "category");
  const attributesList = transformFilterArray(attributes, "attribute");

  // Initial meta data
  const metaData = {
    title: `{{pre}}$t(header.site_header) - ${title}`,
    description: "{{pre}}$t(header.ofb_is_here_to_help_text)",
  };

  return (
    <GlobalContext.Provider
      value={{
        ...value,
        metaData,
        themeColor,
        categories: categoriesList,
        attributes: attributesList,
        userLocation: locationString,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.shape(globalDataShape),
};
