import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { locationDetailShape } from "frontend/shapes";
import { useLocationApi, useMobile, useLocations } from "frontend/hooks";
import {
  getDirectionsLink,
  getFormattedHours,
  getFormattedClosures,
} from "frontend/helpers";
import { Link, Button } from "../ButtonOrLink";
import CategoriesList from "../CategoriesList";
import Distance from "../Distance";
import AttributeList from "../AttributeList";
import DetailNav from "./DetailNav";
import DetailSEO from "./DetailSEO";
import T from "../Translate";

import styles from "./Detail.module.scss";

const Detail = ({ containerClass }) => {
  const { location: id } = useParams();
  const {
    i18n: { language },
  } = useTranslation();
  const [{ location }] = useLocationApi(id);
  const { closeFilters } = useMobile();
  const { setPosition, setMarkerId, isLoading } = useLocations();

  // Close filters when panel opens
  useEffect(() => {
    closeFilters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Focus on map location when location API returns lat/lng
  useEffect(() => {
    if (location.latitude && location.longitude && !isLoading) {
      setMarkerId(id);
      setPosition({
        zoom: 16,
        center: { lat: location.latitude, lng: location.longitude },
      });
    }
  }, [location.latitude, location.longitude, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    name,
    distance,
    comment,
    address,
    phone,
    location_hours: locationHours,
    hours_comment: hoursComment,
    website,
    location_attributes: attributes,
    location_categories: categories,
    latitude,
    longitude,
    served_areas: servedAreas,
    future_closures: closures,
  } = location;

  return (
    <section className={classNames("slide", styles.detail, containerClass)}>
      <DetailSEO name={name} />
      <DetailNav />
      <div className={styles.detail__inner} aria-live="polite">
        <header className={styles.detail__header}>
          <h2 className={styles["detail__header-text"]}>{name}</h2>
          {distance && <Distance value={distance} />}
        </header>
        <div className={styles.detail__description}>
          <p>
            <T i18nKey="detail.default_description" />
          </p>
          {comment && <p>{comment}</p>}
          <CategoriesList categories={categories} tag="p" />
          {attributes && attributes.length > 0 && (
            <AttributeList large attributes={attributes} />
          )}
        </div>
        <div className={styles.detail__details}>
          {((locationHours && locationHours.length > 0) || hoursComment) && (
            <>
              <h3 className={styles["detail__details-header"]}>
                <T translate="detail.hours_header" />
              </h3>
              <div className={styles["detail__details-text"]}>
                {locationHours && (
                  <ul>
                    {getFormattedHours(locationHours, language).map(
                      (item, i) => (
                        <li
                          key={i}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item),
                          }}
                        ></li>
                      )
                    )}
                  </ul>
                )}
                {hoursComment && <p>{hoursComment}</p>}
                {closures && (
                  <ul className={styles.detail__closures}>
                    {getFormattedClosures(closures, language).map((item, i) => (
                      <li key={i}>
                        <span>{item.date}:</span> {item.comment}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </>
          )}
          {phone && (
            <>
              <h3 className={styles["detail__details-header"]}>
                <T translate="detail.phone_header" />
              </h3>
              <p>
                <Link href={`tel:${phone}`}>{phone}</Link>
              </p>
            </>
          )}
          {address && (
            <>
              <h3 className={styles["detail__details-header"]}>
                <T translate="detail.address_header" />
              </h3>
              <p className={styles.detail__address}>{address}</p>
            </>
          )}
          {website && (
            <>
              <h3 className={styles["detail__details-header"]}>
                <T translate="detail.website_header" />
              </h3>
              <p>
                <Link href={website} target="_blank">
                  {website}
                </Link>
              </p>
            </>
          )}
          {servedAreas && servedAreas.length > 0 && (
            <>
              <h3 className={styles["detail__details-header"]}>
                <T translate="detail.served_areas_header" />
              </h3>
              <ul>{servedAreas && <li>{servedAreas.join(", ")}</li>}</ul>
            </>
          )}
        </div>
        <div className={classNames("is-hidden-print", styles.detail__buttons)}>
          <Button
            href={getDirectionsLink({ latitude, longitude, address })}
            target="_blank"
          >
            <T translate="detail.directions_button" />
          </Button>
          <Button
            onClick={() => {
              window.print();
            }}
          >
            <T translate="detail.print_button" />
          </Button>
        </div>
      </div>
    </section>
  );
};

Detail.propTypes = {
  location: PropTypes.shape(locationDetailShape),
  containerClass: PropTypes.string,
};

export default Detail;
