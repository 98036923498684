import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGlobal } from "frontend/hooks";
import Dropdown from "../Dropdown";

function getSelectedLanguage(languageOptions, userLanguages) {
  const availableOptions = languageOptions.filter((item) => {
    return userLanguages.includes(item.value);
  });
  return availableOptions.length > 0
    ? availableOptions[availableOptions.length - 1]
    : languageOptions[0];
}

const SelectLanguage = () => {
  const { t, i18n } = useTranslation();
  const { languages } = useGlobal();

  const language = useMemo(() => {
    return getSelectedLanguage(languages, i18n.languages);
  }, [i18n.languages, languages]);

  const handleOnChange = useCallback(
    ({ value }) => {
      i18n.changeLanguage(value);
      if (window.ga && typeof window.ga === "function") {
        window.ga("send", "event", {
          eventCategory: "Language",
          eventAction: "select-language",
          eventLabel: value,
        });
      }
    },
    [i18n.changeLanguage] // eslint-disable-line
  );

  return language ? (
    <Dropdown
      id="selectLanguage"
      ariaLabel={t("menu.language_select_label")}
      items={languages}
      onChange={handleOnChange}
      defaultValue={language.value}
    />
  ) : null;
};

SelectLanguage.propTypes = {};

export default SelectLanguage;
