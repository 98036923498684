import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  usePopoverState,
  Popover,
  PopoverDisclosure,
  PopoverBackdrop,
} from "reakit/Popover";
import menuLinks from "frontend/shared/menuLinks";
import { IconMenu, IconClose } from "../Icons";
import T from "../Translate";
import SelectLanguage from "../SelectLanguage";
import BackToSite from "../BackToSite";
import { Button, Link } from "../ButtonOrLink";

import styles from "./Menu.module.scss";

const Menu = () => {
  const menu = usePopoverState();

  const handleClose = () => {
    menu.hide();
  };

  return (
    <div className="is-hidden-print" style={{ "--header-height": "85px" }}>
      {menu.visible && (
        <div aria-hidden="true" className={styles.menu__backdrop}></div>
      )}

      <PopoverDisclosure {...menu} className={styles.menu__button}>
        {menu.visible ? (
          <>
            <IconClose />
            <span className="sr-only">Close Navigation</span>
          </>
        ) : (
          <>
            <IconMenu />
            <span className="sr-only">Open Navigation</span>
          </>
        )}
      </PopoverDisclosure>
      <div
        aria-hidden="true"
        className={classNames(styles.menu__backdrop, {
          "is-hidden": !menu.visible,
        })}
      ></div>
      <Popover
        {...menu}
        preventBodyScroll
        className={styles.menu__wrapper}
        aria-label="Menu"
        as="nav"
      >
        <ul>
          <li className={classNames(styles.menu__item, styles.menu__separator)}>
            <BackToSite />
          </li>
          <li className={classNames(styles.menu__item, styles.menu__separator)}>
            <SelectLanguage />
          </li>
          {menuLinks.map((item, i) => (
            <li
              key={item.id}
              className={classNames(styles.menu__item, {
                [styles.menu__separator]: i === menuLinks.length - 1,
              })}
            >
              <T translate={item.textKey} isHTML />
            </li>
          ))}
          <li className={styles.menu__item}>
            <Button onClick={handleClose}>
              <T translate={"menu.close"} />
            </Button>
          </li>
        </ul>
      </Popover>
    </div>
  );
};

Menu.propTypes = {};

export default Menu;
