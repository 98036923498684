import React, { useState, useEffect, useRef } from "react";

export default function useDebounce(value, delay, immediate) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  // Keep track of the current timeout
  const timeoutRef = useRef();

  useEffect(() => {
    // Timeout function
    const updateLater = () => {
      timeoutRef.current = null;
      setDebouncedValue(value);
    };

    // Check if we should fire the call now
    const callNow = immediate && !timeoutRef.current;

    // Clear any existing timeouts, create a new timeout
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(updateLater, delay);

    // If we should call now, do it!
    if (callNow) setDebouncedValue(value);

    return () => {
      // Clean up any existing timeouts
      clearTimeout(timeoutRef.current);
    };
  }, [value, delay, immediate]);

  return debouncedValue;
}
