import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMobile } from "frontend/hooks";
import { Button } from "../ButtonOrLink";
import { IconOpenClose } from "../Icons";

import styles from "./MobileToggle.module.scss";

const MobileToggle = ({ containerClass }) => {
  const { t } = useTranslation();
  const {
    filtersOpen,
    toggleFiltersOpen,
    closeFilters,
    view,
    setView,
  } = useMobile();
  const { pathname } = useLocation();
  const history = useHistory();
  const values = ["results", "map"];

  const handleFilterToggle = () => {
    toggleFiltersOpen();
    if (pathname !== "/") {
      history.push("/");
    }
  };

  const handleViewToggle = (e) => {
    setView(e.currentTarget.value);
    closeFilters();
    if (pathname !== "/") {
      history.push("/");
    }
  };

  return (
    <div
      className={classNames("is-hidden-print", styles.toggles, containerClass)}
      data-open={filtersOpen}
    >
      <Button
        onClick={handleFilterToggle}
        icon={<IconOpenClose isOpen={filtersOpen} />}
      >
        {filtersOpen
          ? t("filters.close_filters_label")
          : t("filters.open_filters_label")}
      </Button>
      <div
        className={styles.toggles__group}
        role="radiogroup"
        aria-label="Toggle between map and results"
        hidden={filtersOpen}
      >
        {values.map((value) => (
          <label key={`${value}Radio`} className={styles.toggles__label}>
            <input
              type="radio"
              aria-checked={view === value}
              className={styles.toggles__radio}
              value={value}
              name={`${value}Radio`}
              checked={view === value}
              onChange={handleViewToggle}
            />
            <span
              className={classNames(styles.toggles__option, {
                [styles.toggles__selected]: view === value,
              })}
            >
              {t(`menu.${value}_button`)}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

MobileToggle.propTypes = {
  containerClass: PropTypes.string,
};

export default MobileToggle;
