import "whatwg-fetch";
import qs from "query-string";

export default function apiBase({ path, headers, params, onSuccess, onError }) {
  const url = params
    ? path +
      "?" +
      qs.stringify(params, {
        arrayFormat: "bracket",
        skipNull: true,
        skipEmptyString: true,
      })
    : path;

  return window
    .fetch(url, { headers })
    .then((response) => {
      if (response.status !== 200) {
        // eslint-disable-next-line
        console.log(`API Request completed but status was ${response.status}`);
        onError({ response });
      } else {
        response.json().then(onSuccess);
      }
    })
    .catch((error) => {
      if (onError) {
        onError({ error });
      }
    });
}
