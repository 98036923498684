import React from "react";
import PropTypes from "prop-types";
import { useGlobal } from "frontend/hooks";

import styles from "./Logo.module.scss";

const Logo = () => {
  const { logo, title } = useGlobal();

  return (
    <img className={styles.logo} src={logo.url} type={logo.type} alt={title} />
  );
};

Logo.propTypes = {};

export default Logo;
