import React from "react";
import PropTypes from "prop-types";

const IconLocation = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11.138" height="16.09">
    <path
      data-name="Path 1"
      d="M5.569 0a5.569 5.569 0 015.569 5.569c0 3.076-5.569 10.521-5.569 10.521S0 8.644 0 5.569A5.569 5.569 0 015.569 0z"
    />
  </svg>
);

IconLocation.propTypes = {};

export default IconLocation;
