import React, { useState } from "react";
import PropTypes from "prop-types";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { useMobile, useGlobal } from "frontend/hooks";
import MapLocations from "./MapLocations";
import MapWrapper from "./MapWrapper";

const Map = ({ containerClass }) => {
  const { googleKey } = useGlobal();
  const { view, filtersOpen } = useMobile();

  return (
    <MapWrapper
      googleKey={googleKey}
      isHidden={view !== "map" || filtersOpen}
      containerClass={containerClass}
    >
      <MapLocations />
    </MapWrapper>
  );
};

Map.propTypes = {
  containerClass: PropTypes.string,
};

export default Map;
